import React from "react";

const Page = class extends React.Component {
    render() {
        return(
            <div class="bot-card">
                <div class="bot-card-head">
                    <h3 class="bot-card-text">
                        {this.props.botJob}
                    </h3>
                </div>
                <p class="ourteambotinfo">
                {this.props.botDescription}
                </p>
                {/* <div id="tryit-text">
                    <a href="/">
                        <b>Read More</b>
                    </a>
                </div>        */}
            </div>
        )
    }
}
export default Page