import React from "react";
import Header from "../../components/header"
import Footer from "../../components/footer"
import { Helmet } from "react-helmet"
import InfoCard from "../../components/infocard"

const Page = class extends React.Component {
    render() {
        return(
            <>
                <Helmet>
                    <title>ChatForge | "Our Team" Bot</title>
                    <meta name="description" content="FAQ bot service from Chatforge"/>
                </Helmet>
                <body class="mainPage">
                    <Header />
                    <div class="content">
                        <div class="section">
                            <div class="bot-profile-head">
                                <div class="profile-info">
                                    <h1 class="bot-title">
                                        FAQ Bot
                                    </h1>
                                    {/* <h2 class="bot-name">
                                        Event booking & Payment Processing
                                    </h2> */}
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. 
                                    </p>
                                </div>
                            </div>
                            <div class="bot-card-container">
                                <InfoCard botJob="Thing this bot can do" botDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."/>
                                <InfoCard botJob="Thing this bot can do" botDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."/>
                                <InfoCard botJob="Thing this bot can do" botDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."/>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </body>
            </>
        )   
    }    
}

export default Page
